<template>
  <div class="main">
    <!-- <loading-cmp v-if="showLoad"></loading-cmp> -->
  </div>
</template>

<script>
// @ is an alias to /src

import loadingCmp from "../components/loadingCmp.vue";

export default {
  name: "Home",
  components: { loadingCmp },
  data() {
    return {
      showLoad: true,
    };
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
</style>
